
.StoreMarketingLayout{
    height: 100%;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            padding: 30px;
        }
    }
    .headerStep{
        margin-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .stepOne{
            display: flex;
            flex-direction: column;
            align-items: center;
            .num{
                line-height: 1;
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #D1D2DB;
                color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                line-height: 40px;
            }
            .numActive{
                background: #1122D8;
            }
            .text{
                line-height: 1;
                color: #878994;
                font-size: 14px;
                font-weight: 500;
                margin-top: 10px;
            }
        }
        .arrows{
            height: 10px;
            margin-bottom: 20px;
        }
    }
    .StoreMarketingForm{
        margin-top: 76px;
    }
}
